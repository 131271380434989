

























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import Embeddable from '../Common/Embeddable.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide, ItemCyclerEmbeddableSlide, ItemCyclerReiszableImageSlide } from '../../app_code/ItemCycler';

@Component({
  components: {
    Embeddable,
  },
})
export default class ItemCyclerItem extends Vue {
  @Prop()
  private readonly item!: ItemCyclerSlide;

  @Prop()
  private readonly active!: boolean;

  @Prop()
  private readonly fullScreen!: boolean;

  private isDragging = false;

  private startX = 0;

  private startY = 0;

  private dragThreshold = 10; // amount mouse can move during a click before its not counted as a click

  getEmbeddableSlide(): ItemCyclerEmbeddableSlide {
    return this.item as ItemCyclerEmbeddableSlide;
  }

  getImageSlide(): ItemCyclerImageSlide {
    return this.item as ItemCyclerImageSlide;
  }

  getResizableImageSlide(): ItemCyclerReiszableImageSlide {
    return this.item as ItemCyclerReiszableImageSlide;
  }

  fullScreenToggle(): void {
    this.$emit('fullScreenToggle');
  }

  // REPLACE ON CLICK
  onMouseDown(event: MouseEvent): void {
    this.isDragging = false;
    this.startX = event.clientX;
    this.startY = event.clientY;
  }

  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) return;
    const deltaX = Math.abs(event.clientX - this.startX);
    const deltaY = Math.abs(event.clientY - this.startY);
    if (deltaX > this.dragThreshold || deltaY > this.dragThreshold) {
      this.isDragging = true;
    }
  }

  onMouseUp(): void {
    if (!this.isDragging) {
      this.fullScreenToggle();
    }
    this.isDragging = false;
  }

  onMouseLeave(): void {
    this.isDragging = false;
  }

  onTouchStart(event: TouchEvent): void {
    this.isDragging = false;
    const touch = event.touches[0];
    this.startX = touch.clientX;
    this.startY = touch.clientY;
  }

  onTouchMove(event: TouchEvent): void {
    if (this.isDragging) return;
    const touch = event.touches[0];
    const deltaX = Math.abs(touch.clientX - this.startX);
    const deltaY = Math.abs(touch.clientY - this.startY);
    if (deltaX > this.dragThreshold || deltaY > this.dragThreshold) {
      this.isDragging = true;
    }
  }

  onTouchEnd(): void {
    if (!this.isDragging) {
      this.fullScreenToggle();
    }
    this.isDragging = false;
  }
}
